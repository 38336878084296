import $ from 'jquery';
// Make jQuery available out of bundle
window.jQuery = $;
window.$ = $;

/*
 * App wrapper
 */
const app = {
  elExists: (arr) => {
    let isExist = false;
    $.each(arr, function(index, value) {
      if ($(value).length) {
        isExist = true;
      }
    });
    return isExist;
  },
};

export default app;
